import { useState } from "react";

const videos = [
  {
    title: "Face Tracking multiple",
    embedId: "Nx2HA4X_3Ck",
  },
  {
    title: "Lane Detection night",
    embedId: "Y0IGwjq8TXw",
  },
  {
    title: "Jun office",
    embedId: "aJflz4R0waU",
  },
  {
    title: "Helicopter short",
    embedId: "cHOGy6zwsIw",
  },
  {
    title: "Drone Car Tracking",
    embedId: "ifAZGPq-EL8",
  },
  {
    title: "My face",
    embedId: "twGLcCTPEL0",
  },
  {
    title: "Heliport",
    embedId: "vtQAVe6Ne9I",
  },
  {
    title: "pose jun col",
    embedId: "D-YBuJTtmjQ",
  },
];

const VideoList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = 4;
  const totalPages = Math.ceil(videos.length / videosPerPage);

  const nextSlide = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const prevSlide = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

//   useEffect(() => {
//     const timer = setInterval(nextSlide, 5000); // Auto-advance every 5 seconds
//     return () => clearInterval(timer);
//   }, []);

  const currentVideos = videos.slice(
    currentPage * videosPerPage,
    (currentPage + 1) * videosPerPage
  );

  const renderVideoCard = (video) => (
    <div
      key={video.id}
      className="overflow-hidden rounded-lg shadow-md"
    >
      <div className="relative aspect-video">
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${video.embedId}?si=A7wU_a_mMt-ezkHS`}
          title={video.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full"
        ></iframe>
      </div>
      {/* <div className="p-4">
        <h3 className="text-base font-semibold truncate capitalize">{video.title}</h3>
      </div> */}
    </div>
  );

  return (
    <>
      <div className="w-full max-w-7xl mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4 text-center">Demo videos</h2>
        <div className="relative">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {currentVideos.map((video) => renderVideoCard(video))}
          </div>
          <button
            className="absolute top-1/2 -left-12 -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100"
            onClick={prevSlide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">Previous page</span>
          </button>
          <button
            className="absolute top-1/2 -right-12 -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100"
            onClick={nextSlide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
            <span className="sr-only">Next page</span>
          </button>
        </div>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              className={`mx-1 w-2 h-2 rounded-full ${
                index === currentPage ? "bg-blue-600" : "bg-gray-300"
              }`}
              onClick={() => setCurrentPage(index)}
            >
              <span className="sr-only">Go to page {index + 1}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default VideoList;
